input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

a {
  text-decoration: none;
}

.position-relative {
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  background-color: #fafafa;
}

.header .links {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.header-link {
  padding: 10px 0;
  margin: 0 10px;
  color: #000000;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease;
}

.header-link:hover {
  border-bottom: 2px solid #000000;
}

.header-link.active {
  border-bottom: 2px solid #000000;
}

.brand-logo {
  max-width: 154px;
}

.brand-name {
  color: #f5d312;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

.brand-name em {
  font-style: normal;
  font-weight: 800;
}

.uploaded {
  margin: 20px 0;
  font-weight: 500;
  font-size: 14px;
}

.centered-main {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
}

.invoice-main {
  min-height: calc(100vh - 132px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  padding: 30px;
}

.dashboard-main {
  min-height: calc(100vh - 132px);
  background-color: #fafafa;
  padding: 30px;
}

.main-header {
  padding: 0 30px;
  background-color: #fafafa;
  min-height: unset !important;
}

@media only screen and (min-width: 320px) {

  .dashboard-main,
  .invoice-main,
  .main-header {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media only screen and (min-width: 600px) {

  .dashboard-main,
  .invoice-main,
  .main-header {
    padding-left: 18px;
    padding-right: 18px;
  }
}

.login-form {
  background-color: #ffffff;
  width: 425px;
  padding: 25px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 0 20px -10px #cdcdcd;
}

.login-banner {
  text-align: center;
  margin-bottom: 40px;
}

.login-banner img {
  width: 100px;
  margin-bottom: 20px;
}

.login-banner h1 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.form-box {
  position: relative;
  background-color: #ffffff;
  padding: 25px;
  border-radius: 8px;
  margin-bottom: 30px;
  box-shadow: 0 0 20px -10px #cdcdcd;
}

.banner-heading {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 15px 25px;
  margin-bottom: 15px;
  background-color: #ffffff;
  box-shadow: 0 0 20px -10px #cdcdcd;
}

.banner-heading h2 {
  margin: 0;
  font-size: 24px;
}

.status-badge {
  position: relative;
  padding: 4px;
  line-height: 16px;
  border: 1px solid;
  border-radius: 50px;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status-badge img {
  width: 15px;
  margin-left: 10px;
}

/* 
.status-badge::before {
  position: absolute;
  content: '\2713';
  left: 15px;
  top: 4px;
  font-size: 20px;
} */

.status-badge.paid {
  border-color: #5cb85c;
  background: rgba(92, 184, 92, 0.2);
  color: #5cb85c;
}

.status-badge.unpaid {
  border-color: #b4c5d5;
  background: rgba(225, 232, 238, 0.2);
  color: #b4c5d5;
}

.invoice-icons {
  text-align: center;
  cursor: pointer;
}

.invoice-icons img {
  padding: 0 5px;
}

.trip-box {
  border-top: 1px dashed #cdcdcd;
  padding: 10px 0;
  margin-top: 10px;
  position: relative;
}

.trip-box1 {
  border-top: 1px dashed #cdcdcd;
  padding: 10px 0;
  margin-top: 10px;
}

.remove-btn {
  position: absolute;
  bottom: 0;
  border: 0;
  cursor: pointer;
  right: 10px;
  padding: 10px 12px;
  font-size: 14px;
  margin: 0;
  font-weight: 500;
  color: red;
  background-color: #fafafa;
  transition: all 0.4s ease;
}

.remove-btn:hover {
  background-color: red;
  color: #ffffff;
}

.card-header {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.card-img {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border-radius: 50%;
  padding: 5px;
}

.card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.card-header h3 {
  margin: 0;
}

.card-header .company-badge {
  background-color: #eeeeee;
  padding: 0 10px;
  border-radius: 5px;
  color: #353535;
  font-weight: 500;
  font-size: 14px;
  margin-top: 5px;
}

.card-body .main-details {
  margin: 10px 0;
}

.card-flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-light-text {
  font-size: 15px;
  color: #797979;
}

.card-flex p {
  margin: 0;
}

.driver-profile-td {
  display: flex;
  align-items: center;
  justify-content: center;
}

.driver-profile-td img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.driver-profile-td p {
  margin: 0;
  font-weight: 500;
}

.bank-details {
  padding-left: 15px;
}

.bank-details p {
  font-size: 14px;
  margin: 8px 0;
}

/************************** Template View *************************/

.table {
  border: none;
  width: 100%;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.text-center {
  text-align: center !important;
}

.header-email {
  margin-bottom: 30px;
  margin-top: 20px;
}

.header-email img {
  max-width: 200px;
}

.header h1 {
  color: rgba(240, 204, 24, 1);
  font-size: 34px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 20px;
}

.customer-details p {
  margin: 0;
  font-weight: 400;
}

.header-text {
  font-size: 16px;
  font-weight: 400;
}

.header-text-small {
  font-size: 14px;
  font-weight: 400;
}

.header-heading {
  font-size: 16px;
  font-weight: 800;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.logo img {
  width: 60px;
}

.logo-text h3,
.logo-text p {
  margin: 0;
}

.header-dates {
  text-align: right;
  padding: 25px 0;
  margin-top: 20px;
}

.header-dates p:first-child {
  margin-bottom: 0;
}

.header-dates p:last-child {
  margin: 0;
}

.table-inner {
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
}

.thead {
  border-bottom: 2px solid #000000;
}

.thead th {
  padding: 22px 10px;
  text-align: left;
}

.thead th:first-child {
  padding-left: 0;
}

.table-inner th:last-child,
.table-inner td:last-child {
  /* padding-right: 30px; */
  text-align: right;
}

.list-row td {
  padding: 25px 5px;
}

.price-heading {
  margin-bottom: 0;
  font-weight: 700;
}

.summary-box {
  padding: 15px 0;
  /* padding-right: 30px; */
  text-align: right;
  border-top: 1px solid #000000;
}

.summary-box p {
  margin-bottom: 0;
}

.total-price td {
  padding: 10px;
  font-size: 20px;
  font-weight: 700;
  border-style: double;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
}

.text-box {
  margin-top: 50px;
  margin-bottom: 10px;
}

.about-text p {
  margin: 0;
}

.about-text p:last-child {
  font-weight: 600;
}

.custom-netto {
  margin-top: 5px;
  font-size: 14px;
  color: rgb(176 176 176);
}

.custom-netto input {
  font-size: 14px;
  background-color: #ffffff !important;
  border: 0;
  color: rgb(176 176 176) !important;
}

.email-counter {
  position: relative;
}

.email-counter span {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 18px;
  height: 18px;
  font-size: 10px;
  background: #ffffff;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0 0 2px 0px #cdcdcd;
}

@media only screen and (max-width: 768px) {
  .brand-logo {
    width: 50px;
  }

  .brand-name {
    font-size: 20px;
  }

  .form-box,
  .login-form {
    padding: 10px;
  }

  /* .banner-heading {
		display: block;
	} */
  .banner-heading h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .total-price td {
    font-size: 16px;
  }
}

input[type="time"] {
  position: relative;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  display: block;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  background: transparent;
}