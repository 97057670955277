/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  /* text-decoration: underline;
    color: rgba(0, 0, 255, 0.644); */
}

/* Tooltip text */
.tooltip .tooltiptext {
  display: none;
  /* height: 30px; */
  /* width: 32px; */
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -40px;
  /* left: -40px; */
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  display: block;
  /* transform: translateX(-100%); */
  /* opacity: 0.7; */
  transition: 10s ease;
}

.hello {
  display: flex;
  gap: 10px;
  /* align-items: center; */
  flex-wrap: wrap;
}

.sImg {
  height: 50px;
  width: 50px;
  border-radius: 50px;
}

.sImgmulti {
  height: 80px;
  width: 80px;
  /* border-radius: 50px; */
}
